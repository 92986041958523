import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { PAYPAL_CLIENT_ID } from "src/app/common/constants";

@Component({
  selector: "pay",
  templateUrl: "./pay.component.html",
  styleUrls: ["./pay.component.css"],
})
export class PayComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(DOCUMENT) private document,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const s = this.document.createElement("script");
    s.type = "text/javascript";
    s.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&enable-funding=venmo&currency=USD`;
    s.setAttribute("data-sdk-integration-source", "button-factory");
    const __this = this;
    s.onload = function () {
      __this.afterScriptAdded();
    };
    this.elementRef.nativeElement.appendChild(s);
  }

  afterScriptAdded() {
    const s = this.document.createElement("script");
    s.type = "text/javascript";
    s.text = `
      function initPayPalButton() {
      var description = document.querySelector(
        "#smart-button-container #description"
      );
      var amount = document.querySelector("#smart-button-container #amount");
      var descriptionError = document.querySelector(
        "#smart-button-container #descriptionError"
      );
      var priceError = document.querySelector(
        "#smart-button-container #priceLabelError"
      );
      var invoiceid = document.querySelector(
        "#smart-button-container #invoiceid"
      );
      var invoiceidError = document.querySelector(
        "#smart-button-container #invoiceidError"
      );
      var invoiceidDiv = document.querySelector(
        "#smart-button-container #invoiceidDiv"
      );

      var elArr = [description, amount];

      if (invoiceidDiv.firstChild.innerHTML.length > 1) {
        invoiceidDiv.style.display = "block";
      }

      var purchase_units = [];
      purchase_units[0] = {};
      purchase_units[0].amount = {};

      function validate(event) {
        return event.value.length > 0;
      }

      paypal
        .Buttons({
          style: {
            color: "blue",
            shape: "rect",
            label: "pay",
            layout: "vertical",
          },

          onInit: function (data, actions) {
            actions.disable();

            if (invoiceidDiv.style.display === "block") {
              elArr.push(invoiceid);
            }

            elArr.forEach(function (item) {
              item.addEventListener("keyup", function (event) {
                var result = elArr.every(validate);
                if (result) {
                  actions.enable();
                } else {
                  actions.disable();
                }
              });
            });
          },

          onClick: function () {
            if (description.value.length < 1) {
              descriptionError.style.visibility = "visible";
            } else {
              descriptionError.style.visibility = "hidden";
            }

            if (amount.value.length < 1) {
              priceError.style.visibility = "visible";
            } else {
              priceError.style.visibility = "hidden";
            }

            if (
              invoiceid.value.length < 1 &&
              invoiceidDiv.style.display === "block"
            ) {
              invoiceidError.style.visibility = "visible";
            } else {
              invoiceidError.style.visibility = "hidden";
            }

            purchase_units[0].description = description.value;
            purchase_units[0].amount.value = amount.value;

            if (invoiceid.value !== "") {
              purchase_units[0].invoice_id = invoiceid.value;
            }
          },

          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: purchase_units,
            });
          },

          onApprove: function (data, actions) {
            return actions.order.capture().then(function (orderData) {
              // Full available details
              console.log(
                "Capture result",
                orderData,
                JSON.stringify(orderData, null, 2)
              );

              // Show a success message within this page, e.g.
              const element = document.getElementById("paypal-button-container");
              element.innerHTML = "";
              element.innerHTML = "<h3>Thank you for your payment!</h3>";

              // Or go to another URL:  actions.redirect('thank_you.html');
            });
          },

          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button-container");
    }
    initPayPalButton();
    `;
    this.elementRef.nativeElement.appendChild(s);
  }
}
